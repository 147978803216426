@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Arbutus+Slab&family=Poppins&display=swap");

.Arbutus-font {
  font-family: "Arbutus Slab", serif;
}

.Poppins-font {
  font-family: "Poppins", sans-serif;
}

.text_main {
  color: #242527;

  text-align: center;
  text-shadow: 0px 1px 4px rgba(255, 59, 1, 0.5);
  font-family: Arbutus Slab;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#tsparticles {
  height: 100px !important;
}

.hero {
  font-size: 1.6rem;
  background: radial-gradient(
    121.7% 121.7% at 49.11% -8.55%,
    #ff9413 55.62%,
    #ff6b0b 95.36%
  );
  z-index: 10;

  width: 100dvw;
  height: 100dvh;
  flex-shrink: 0;
  color: #ffff;
}

.header-bg {
  background: radial-gradient(
    121.7% 121.7% at 49.11% -8.55%,
    #ff9413 55.62%,
    #ff6b0b 95.36%
  ) !important;
}

.tsparticles-canvas-el canvas {
  width: 500px !important;
}
